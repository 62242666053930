import { createSelector } from 'reselect';

const getMetaData = state => state.searchAndBrowse.metaData;

export const getMetaDataCanonical = createSelector(
  [getMetaData],
  metaData => metaData?.canonicalTag ?? null,
);

export const getMetaDataDescription = createSelector(
  [getMetaData],
  metaData => metaData?.description ?? null,
);

export const getMetaDataKeywords = createSelector([getMetaData], metaData => {
  const keywords = metaData?.keywords ?? null;

  return Array.isArray(keywords) ? keywords.join(' ') : keywords;
});

export const getMetaDataNoIndex = createSelector(
  [getMetaData],
  metaData => metaData?.turnOffIndexing ?? false,
);

export const getMetaDataTitle = createSelector([getMetaData], metaData => metaData?.title ?? null);
