import { connect } from 'react-redux';

import { getGridItemsCount, isAddAllToBasket } from 'redux/modules/search-and-browse/selectors';
import { addAllBrowseItemsToTrolley } from 'redux/modules/add-all-items/actions/add-all-browse-items-to-trolley';
import { isUserLoggedIn } from 'redux/modules/sessions/selectors';

import AddAllItemsButton from 'components/AddAllItemsButton';

export default connect(
  state => ({
    display: isUserLoggedIn(state) && isAddAllToBasket(state),
    itemCount: getGridItemsCount(state),
  }),
  {
    addAllItems: addAllBrowseItemsToTrolley,
  },
)(AddAllItemsButton);
