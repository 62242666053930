import React from 'react';
import PropTypes from 'prop-types';

import { C62, weightOptions } from 'constants/weightOptions';

import { formatAsPositivePoundsOrPence } from 'utils/currency';

import Thumbnail from 'components/Images/Thumbnail';

import styles from './ModalProductPod.scss';

const ModalProductPod = ({
  name,
  price,
  priceQualifier,
  priceYouPay,
  productId,
  quantity: { amount, uom },
}) => (
  <div className={styles.productPod} key={productId}>
    <Thumbnail itemId={productId} name={name} tooltipTrigger />
    <div className={styles.details}>
      <span className={styles.name}>{name}</span>
      {amount && (
        <span className={styles.quantity}>
          Qty: {amount}
          {uom && uom !== C62 ? weightOptions[uom].label : ''}
        </span>
      )}
      {price && (
        <span className={styles.price} data-testid="price-wrapper">
          {priceYouPay && (
            <span className={styles.priceYouPay} data-testid="price-you-pay">
              Price you pay
            </span>
          )}
          {formatAsPositivePoundsOrPence(price)}{' '}
          {priceQualifier && (
            <span className={styles.priceQualifier} data-testid="price-qualifier">
              {priceQualifier}
            </span>
          )}
        </span>
      )}
    </div>
  </div>
);

ModalProductPod.displayName = 'ModalProductPod';

ModalProductPod.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  priceQualifier: PropTypes.string,
  priceYouPay: PropTypes.bool,
  productId: PropTypes.string.isRequired,
  quantity: PropTypes.shape({
    amount: PropTypes.number,
    uom: PropTypes.string,
  }),
};

ModalProductPod.defaultProps = {
  name: '',
  price: null,
  priceQualifier: null,
  priceYouPay: false,
  quantity: {},
};

export default ModalProductPod;
