import { connect } from 'react-redux';

import { getAddAllItemsSuccessfulCount } from 'redux/modules/add-all-items/selectors';
import { getGroupedConflictedProducts } from 'redux/modules/trolley-errors/selectors';
import { getCustomerId } from 'redux/modules/sessions/selectors';

import clearProductErrors from 'redux/modules/trolley-errors/actions/clear-trolley-errors';

import AddAllItemsErrorModal from './AddAllItemsErrorModal';

export default connect(
  state => {
    const conflictedProductGroups = getGroupedConflictedProducts(state);

    return {
      conflicts: conflictedProductGroups,
      isOpen: conflictedProductGroups.length > 0,
      successfulCount: getAddAllItemsSuccessfulCount(state),
      customerId: getCustomerId(state),
    };
  },
  {
    clearProductErrors,
  },
)(AddAllItemsErrorModal);
