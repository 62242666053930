import React from 'react';
import PropTypes from 'prop-types';

import ModalProductGroup from 'components/wdx/ProductGroup/Modal';

const GroupedProductList = ({ productGroups }) =>
  productGroups.map(productGroup => {
    const { message } = productGroup;

    return (
      <ModalProductGroup
        {...productGroup}
        key={`product-group-${message ? message.replace(/ /gi, '-') : 'undefined'}`}
      />
    );
  });

GroupedProductList.displayName = 'GroupedProductList';

GroupedProductList.propTypes = {
  productGroups: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ),
};

GroupedProductList.defaultProps = {
  productGroups: [],
};

export default GroupedProductList;
