import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ModalProductPod from 'components/wdx/ProductPod/Modal';

import styles from './ModalProductGroup.scss';

const ModalProductGroup = ({ conflict, message, products }) => (
  <span
    // eslint-disable-next-line react/no-unknown-property
    styles={styles.productGroup}
    key={`product-group-${message ? message.replace(/ /gi, '-') : ''}`}
  >
    {message && (
      <span
        className={classNames(styles.message, { [styles.conflict]: conflict })}
        data-testid="message"
      >
        {message}
      </span>
    )}
    <span>
      {products.map(product => (
        <ModalProductPod {...product} key={product.productId} />
      ))}
    </span>
  </span>
);

ModalProductGroup.displayName = 'ModalProductGroup';

ModalProductGroup.propTypes = {
  conflict: PropTypes.bool,
  message: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
    }),
  ),
};

ModalProductGroup.defaultProps = {
  conflict: false,
  message: null,
  products: [],
};

export default ModalProductGroup;
