import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ModalCloseOptions } from 'constants/modalCloseOptions';
import { modalSeverityValues } from 'constants/modals';
import { trackModalOpened, trackModalClosed } from 'analytics/modal-tracking';

import GroupedProductList from 'components/GroupedProductList';
import ProductsModal from 'components/Modal/ProductsModal';

const getTitle = (count = 0) =>
  `${count} item${
    count === 1 ? ' was' : 's were'
  } successfully added to your trolley.\nSorry, but the following items could not be added to your trolley:`;

const AddAllItemsErrorModal = ({
  clearProductErrors,
  isOpen,
  conflicts,
  successfulCount,
  customerId,
}) => {
  const title = getTitle(successfulCount);

  useEffect(() => {
    if (isOpen) {
      trackModalOpened({
        id: 'addAllItemsErrorModal',
        severity: modalSeverityValues.ERROR,
        title,
        customerId,
      });
    }
  }, [isOpen, customerId, title]);

  const closeModal = useCallback(() => {
    trackModalClosed({
      id: 'addAllItemsErrorModal',
      severity: modalSeverityValues.ERROR,
      title,
      customerId,
    });
    clearProductErrors();
  }, [customerId, title, clearProductErrors]);

  return (
    <ProductsModal
      buttons={[
        {
          buttonText: `Ok`,
          buttonValue: ModalCloseOptions.OK,
        },
      ]}
      closeModal={closeModal}
      show={isOpen}
      testAttrPrefix="add-all-to-trolley-conflicts"
      titleText={title}
    >
      <GroupedProductList productGroups={conflicts} />
    </ProductsModal>
  );
};

AddAllItemsErrorModal.displayName = 'AddAllItemsErrorModal';

AddAllItemsErrorModal.propTypes = {
  customerId: PropTypes.string,
  clearProductErrors: PropTypes.func,
  isOpen: PropTypes.bool,
  conflicts: PropTypes.arrayOf(
    PropTypes.shape({
      conflict: PropTypes.bool,
      message: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          productId: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  successfulCount: PropTypes.number,
};

AddAllItemsErrorModal.defaultProps = {
  customerId: undefined,
  clearProductErrors: () => {},
  isOpen: false,
  conflicts: [],
  successfulCount: 0,
};

export default AddAllItemsErrorModal;
