import React from 'react';
import PropTypes from 'prop-types';

import { modalButtonOrientation } from 'constants/modal-button-orientation';

import Modal from 'components/Modal';

import styles from './ProductsModal.scss';

const ProductsModal = ({
  show,
  closeModal,
  titleText,
  buttons,
  asideText,
  testAttrPrefix,
  children,
}) => (
  <Modal
    isOpen={show}
    closeModal={closeModal}
    titleText={titleText}
    buttons={buttons}
    asideText={asideText}
    buttonOrientation={modalButtonOrientation.VERTICAL}
    dataTest={`${testAttrPrefix}-modal`}
  >
    <div className={styles.panels}>
      <div className={styles.scrollablePanel}>{children}</div>
    </div>
  </Modal>
);

ProductsModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  buttons: PropTypes.array,
  titleText: PropTypes.string,
  children: PropTypes.node,
  asideText: PropTypes.string,
  testAttrPrefix: PropTypes.string,
};

export default ProductsModal;
