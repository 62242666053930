import { getConflictedProducts } from 'redux/modules/trolley-errors/selectors';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { getProductById } from 'redux/modules/entities/selectors/products';
import { getItemByLineNumber } from 'redux/modules/trolley/selectors/get-item';

import { getGridProductLineNumbers } from 'redux/modules/search-and-browse/selectors';

import addAllItemsToTrolley from 'redux/modules/add-all-items/actions/add-all-items';
import itemsSuccessfullyAddedToTrolley from 'redux/modules/trolley-errors/actions/items-successfully-added';

export const addAllBrowseItemsToTrolley = () => (dispatch, getState) => {
  const state = getState();
  const items = getGridProductLineNumbers(state);
  const orderId = getCustomerOrderId(state);

  const orderItems = items.map(lineNumber => {
    const {
      canSubstitute = true,
      noteToShopper = null,
      personalisedMessage = null,
    } = getItemByLineNumber(getState(), lineNumber) ?? {};
    const { id: productId, defaultQuantity } = getProductById(state, lineNumber) || {};

    return {
      canSubstitute,
      lineNumber,
      noteToShopper,
      personalisedMessage,
      productId,
      quantity: defaultQuantity,
      reservedQuantity: 0,
      trolleyItemId: -1,
    };
  });

  return dispatch(addAllItemsToTrolley({ merge: true, orderId, orderItems })).then(() => {
    const itemsAddedCount = orderItems.length - getConflictedProducts(getState()).length;

    dispatch(itemsSuccessfullyAddedToTrolley(itemsAddedCount));
  });
};
